<template>
  <!-- 切换用户组件 -->
  <div class="switchUsers">
    <div class="switchUsers-block">
      <div class="switchUsers-left">
        <div class="user-head-portrait">
          <!-- 头像 -->
          <img
            v-if="cardList.length > 0? true:false"
            :src="headPortrait"
            alt=""
          >
          <div v-else>
            <svg
              style="font-size:2.7rem"
              class="icon"
              aria-hidden="true"
            >
              <use :xlink:href='headPortrait'></use>
            </svg>
          </div>
        </div>
        <div class="user-name">
          <div class="large">
            <span class="in-size">{{nameState}}</span>
            <span>
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href='#icon-youjiantou1'></use>
              </svg>
            </span>
          </div>
          <span class="small-size">
            {{phoneState}}
          </span>
        </div>
      </div>
      <div
        class="switchUsers-right in-size"
        @click="switchClick"
      >
        {{nameState == "未登录" ? '绑卡':'切换用户'}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  mounted () {
    //获取绑卡信息
    this.$store.commit('USER_POST')
  },
  computed: {
    ...mapState(['nameState', 'phoneState', 'headPortrait', 'cardList'])
  },
  methods: {
    //用户绑卡或切换
    switchClick () {
      if (this.nameState != '未登录') {
        this.$emit('switchClick', '/user-data')
      } else {
        this.$emit('switchClick', '/login')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.switchUsers {
  color: rgb(255, 255, 255);
  background-color: @darkColor;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  .switchUsers-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;

    .switchUsers-left {
      display: flex;
      align-items: center;
      .user-head-portrait {
        padding-right: 1rem;
        img {
          border-radius: 50%;
          height: 3rem;
          width: 3rem;
        }
      }
      .user-name {
        display: flex;
        flex-direction: column;
        line-height: 1.7rem;
        padding-top: 0.4rem;
        .icon {
          padding-left: 0.2rem;
          font-size: 1rem;
        }
      }
    }
    .switchUsers-right {
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      border: 1px solid rgb(255, 255, 255);
    }
  }
}
</style>