<template>
  <div
    class="serve"
    @click="serveClick(path,id)"
  >
    <div>
      <svg
        class="icon"
        aria-hidden="true"
      >
        <use
          class="icon-u"
          :xlink:href='icon'
        ></use>
      </svg>
    </div>
    <span>{{name}}</span>
  </div>
</template>

<script>
export default {
  name: 'serve',
  props: {
    name: {
      type: String
    },
    icon: {
      type: String
    },
    path: {
      type: String
    },
    id: {
      type: Number
    }
  },
  methods: {
    serveClick (path, id) {
      this.$store.state.active = id
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
.serve {
  .icon {
    margin-top: 0.5rem;
    font-size: 3rem;
  }
}
</style>