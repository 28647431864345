<template>
  <div class="service">
    <Scroll
      class="region-scroll"
      ref="scroll"
    >
      <div class="service-box">
        <div class="service-head-box">
          <!-- 轮播图 -->
          <Swiper :list="list" />
          <!-- 用户切换 -->
          <SwitchUsers @switchClick="switchClick"></SwitchUsers>
        </div>
        <div class="block-yn">
          <h3>院内服务</h3>
        </div>
        <div>
          <!-- 院内服务 -->
          <div class="serve in-size">
            <Serve
              class="serve-s"
              v-for="item in typeList"
              :key="item.id"
              :name="item.name"
              :icon="'#' + item.logo"
              :path="item.url"
              :id="item.id"
              @click.native="informationService(item.id)"
            />
          </div>
          <!-- 常用功能组件 -->
          <div class="common-functions-title">
            <h3>常用功能</h3>
          </div>
          <div class="list">
            <div
              class="list-each"
              v-for="item in type"
              :key="item.id"
              @click="clickJump(item.url,item.name)"
            >
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use
                  class="icon-u"
                  :xlink:href="'#' + item.logo"
                ></use>
              </svg>
              <span class="in-size">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </Scroll>
  </div>
</template>

<script>
import Swiper from '@/components/content/swiper/Swiper'
import Serve from '@/components/common/serve/Serve'
import SwitchUsers from "@/components/common/switchUsers/switchUsers"
import Scroll from '@/components/content/scroll/BScroll'

import { getMenuInfo } from '@/network/service'

export default {

  name: 'service',
  components: {
    Swiper,
    Serve,
    SwitchUsers,
    Scroll
  },
  data () {
    return {
      value: '',
      //智慧服务
      type: [],
      //院内服务功能
      typeList: [],
      list: [{
        img: '//bpic.588ku.com/illus_water_img/21/01/20/83606779d57c8e0af5a819b4dab6a7b3.jpg!/fw/750/quality/99/unsharp/true/compress/true'
      }, {
        img: '//bpic.588ku.com/back_origin_min_pic/21/03/15/9449869c25fd52c7d6894b607604ac0d.jpg!/fw/720/quality/90/unsharp/true/compress/true'
      }, {
        img: '//bpic.588ku.com/illus_list_pic/21/01/20/8c533aa4788b32fa141bc372da2d4334.jpg!/fw/590/quality/90/unsharp/true/compress/true'
      }, {
        img: '//bpic.588ku.com/back_origin_min_pic/21/03/15/41b123e169b3fdda361dd980c1131808.jpg!/fw/822.85714285714/quality/90/unsharp/true/compress/true'
      }],
    }
  },
  mounted () {
    //首页功能模块
    this.getMenuInfoPost()
  },
  methods: {
    informationService (id) {
      if (id == 3) {
        this.$Toast('暂未开通');
      }
    },
    //首页功能模块
    async getMenuInfoPost () {
      let res = await getMenuInfo()
      if (res.code == '0') {
        this.$store.state.functionalModule = res.data
        //智慧服务
        let data = res.data.sort((a, b) => a.sort - b.sort)
        data.forEach(item => {
          switch (item.appRoleId) {
            case 0:
              this.typeList.push(item)
              break
            case 1:
              this.type.push(item)
              break
          }
        })
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      }
    },
    //用户绑卡或切换
    switchClick (path) {
      this.$router.push(path)
    },
    clickJump (path, menuName) {
      this.$store.dispatch('JUMP', { menuName, _this: this, path })
    }
  },
}
</script>
<style lang="less" scoped>
.service {
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: #ffffff;
  .region-scroll {
    height: calc(100vh - 3.75rem);
    overflow: hidden;
    .head {
      height: 3rem;
      text-align: center;
      h3 {
        background-color: @lightColor;
        color: #ffffff;
        font-size: 1.3rem;
        line-height: 3rem;
        border-bottom: 1px solid @lightColor;
      }
    }
    .search-color {
      background-image: linear-gradient(120deg, #50d996 0%, #3cc7b6 100%);
    }
    .service-box {
      margin-bottom: 5rem;
      .service-head-box {
        background-color: @lightColor;
        padding: 1.5rem 1rem 1.5rem 1rem;
      }
      .block-yn,
      .common-functions-title {
        padding: 1rem;
      }
      .common-functions-title {
        border-top: 5px solid #f5f5f5f5;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
        .list-each {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 6rem;
          width: 25%;
          .icon {
            font-size: 3rem;
          }
          span {
            margin-top: 0.5rem;
          }
        }
      }
      .ts-h3 {
        margin: 1rem 0 0.5rem 0;
        padding: 0 1rem;
      }
      .ts-list {
        padding: 0 1rem;
      }
      .serve {
        display: flex;
        justify-content: space-between;
        height: 7rem;
        padding: 0 1rem;
        .serve-s {
          display: block;
          text-align: center;
          line-height: 2rem;
          background-color: rgb(255, 255, 255);
          white-space: nowrap;
        }
        .serve-s:nth-child(1) {
          width: 25%;
        }
        .serve-s:nth-child(2) {
          width: 50%;
        }
        .serve-s:nth-child(3) {
          width: 25%;
        }
      }
      .serve-list {
        padding: 0 1rem;
      }
      .jk-hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        margin-top: 1rem;
      }
      .jk-list {
        padding: 0 1rem;
        margin-top: 1rem;
      }
    }
  }
}
</style>